<template lang="pug">
.rating(:class="{ unrated: !modelValue && !value }")
  .star(
    v-for="n in 5",
    :onClick="() => { rating = n; }",
    :class="{ readonly }"
  )
    fa(:icon="[iconType(n), 'star']")
</template>
<script setup lang="ts">
const props = defineProps({
  modelValue: Number,
  value: Number,
  readonly: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["update:modelValue"])

const rating = computed<number | undefined>({
  get() {
    return props.readonly ? props.value : props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

const iconType = (n: number) => {
  if (rating.value) {
    if (n <= rating.value) {
      return "fas"
    } else {
      return "far"
    }
  } else {
    return "far"
  }
}
</script>
<style lang="sass">
@import '~/assets/styles/variables'

.rating
  display: inline-block
  text-align: center
  color: orange

  &.unrated
    color: $inactiveTextColor

  .star
    display: inline
    cursor: pointer
    padding: .1em

    &.readonly
      pointer-events: none
</style>
